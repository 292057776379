<template>
    <v-app>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <h3>
                            Class Timetable
                        </h3>
                        <router-link :to="{name:'dashboard'}">
                            Dashboard
                        </router-link>
                        \
                        Class Timetable
                    </div>
                    <div class="col-6 text-right">

                    </div>
                </div>
                <v-row class="text-center bg-filter">
                    <v-form class="form" id="kt_password_change_form" @submit.prevent="getTimeTable">
                        <div class="form-group row">
                            <div class="col-lg-2">
                                <v-select
                                        :items="academicYears"
                                        @change="changeAcademicYear"
                                        :loading="loading"
                                        class="form-control"
                                        v-model="search.academic_year_id"
                                        label="Academic Year"
                                        item-value="id"
                                        item-text="year"
                                        outlined
                                        dense>
                                </v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select
                                        :items="levels"
                                        :loading="loading"
                                        class="form-control"
                                        v-model="search.level_id"
                                        label="Level"
                                        item-value="id"
                                        item-text="title"
                                        outlined
                                        @change="getPrograms"
                                        dense>
                                </v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select
                                        outlined
                                        dense
                                        :loading="loading"
                                        class="form-control"
                                        v-model="search.program_id"
                                        :items="programs"
                                        label="Programs"
                                        item-value="id"
                                        item-text="title"
                                        @change="getGrades"
                                >
                                </v-select>
                            </div>

                            <div class="col-lg-2">
                                <v-select
                                        outlined
                                        :loading="loading"
                                        dense
                                        class="form-control"
                                        v-model="search.grade_id"
                                        :items="gradesLevels"
                                        label="Semester/Year"
                                        item-value="id"
                                        item-text="title"
                                        @change="getAcademicClasses"
                                ></v-select>

                            </div>
                            <div class="col-lg-2">
                                <v-select
                                        outlined
                                        :loading="loading"
                                        dense
                                        class="form-control"
                                        v-model="search.class_id"
                                        :items="academic_classes"
                                        label="Class Name"
                                        item-value="id"
                                        item-text="title"
                                        @change="getTimeTable"
                                ></v-select>

                            </div>


                        </div>
                    </v-form>
                </v-row>


                <v-row class="mt-6" v-if="edit || create">
                    <v-col>
                        <v-text-field
                                v-model="academicClassTimetable.title"
                                outlined
                                dense
                                label="Enter Class Timetable Title"
                                clearable
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn @click="openWeekdayDialog()" depressed color="blue" class="text-white ">
                            {{academicClassTimetable.id ? 'Update' : 'Set'}} WeekDays
                        </v-btn>
                    </v-col>
                </v-row>
                <div class="col-12 mt-4">
                    <div class="table table-stripe">
                        <table class="table table-stripe">
                            <thead>
                            <th>Title</th>
                            <th>Class</th>
                            <th>Batch</th>
                            <th>Program</th>
                            <th>Semester/Year</th>
                            <th>Action</th>
                            </thead>
                            <tbody v-if="time_tables.length">
                            <tr v-for="(timeTable, index) of time_tables" :key="index">
                                <td>
                                    <router-link :to="{name:'time-table-period',params:{time_table_id:timeTable.id}}"
                                                 class="mr-2">
                                        {{timeTable.title}}
                                    </router-link>
                                    <i class="fas fa-circle"
                                       :class="timeTable.is_active?'dot-active':'dot-inactive'"></i></td>
                                <td v-if="timeTable.academic_class">
                                    {{timeTable.academic_class.title}}
                                </td>
                                <td v-if="timeTable.academic_class">
                                    {{timeTable.academic_class.academic_year.title}}
                                </td>
                                <td v-if="timeTable.academic_class">
                                    {{timeTable.academic_class.program.title}}
                                </td>
                                <td v-if="timeTable.academic_class">
                                    {{timeTable.academic_class.grade.title}}
                                </td>
                                <td>
                                    <b-dropdown
                                            size="sm"
                                            variant="link"
                                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                            no-caret
                                            right
                                            no-flip
                                    >
                                        <template v-slot:button-content>
                                            <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                                        </template>
                                        <!--begin::Navigation-->
                                        <div class="navi navi-hover ">

                                            <b-dropdown-text tag="div" class="navi-item">
                                                <a href="#" class="navi-link" @click="editTimeTable(timeTable)"
                                                >
                                                                            <span class="navi-icon">
                                                                              <i class="flaticon-edit"></i>
                                                                            </span>
                                                    <span class="navi-text">  Edit</span>
                                                </a>
                                            </b-dropdown-text>
                                            <b-dropdown-text tag="div" class="navi-item">
                                                <a href="#" class="navi-link" @click="deleteTimeTable(timeTable.id)"
                                                >
                                                                                <span class="navi-icon">
                                                                                  <i class="fas fa-trash"></i>
                                                                                </span>
                                                    <span class="navi-text">  Delete</span>
                                                </a>
                                            </b-dropdown-text>

                                        </div>
                                        <!--end::Navigation-->
                                    </b-dropdown>

                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
        <v-dialog v-model="weekdayDialog" max-width="600">
            <v-card>
                <v-card-title>
                    Class Days
                </v-card-title>
                <v-card-text>
                    <div class="table table-stripe">
                        <table class="table table-stripe">
                            <thead>
                            <th>Set Class</th>
                            <th>Day</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <v-checkbox v-model="academicClassTimetable.is_working_sunday"
                                    ></v-checkbox>
                                </td>
                                <td>Sunday</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-checkbox v-model="academicClassTimetable.is_working_monday"
                                    ></v-checkbox>
                                </td>
                                <td>Monday</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-checkbox v-model="academicClassTimetable.is_working_tuesday"
                                    ></v-checkbox>
                                </td>
                                <td>Tuesday</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-checkbox v-model="academicClassTimetable.is_working_wednesday"
                                    ></v-checkbox>
                                </td>
                                <td>Wednesday</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-checkbox v-model="academicClassTimetable.is_working_thursday"
                                    ></v-checkbox>
                                </td>
                                <td>Thursday</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-checkbox v-model="academicClassTimetable.is_working_friday"
                                    ></v-checkbox>
                                </td>
                                <td>Friday</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-checkbox v-model="academicClassTimetable.is_working_saturday"
                                    ></v-checkbox>
                                </td>
                                <td>Saturday</td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-col class="text-right">
                        <v-btn @click="createOrUpdae()" depressed color="blue" class="text-white">
                            {{academicClassTimetable.id ? 'Update' : 'Create'}}
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-app>

</template>

<script>
    import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
    import UserService from "@/core/services/user/UserService";
    import AcademicCLassTimetableService from "@/core/services/academicClassTimetable/AcademicCLassTimetableService";
    import LevelService from "@/core/services/level/LevelService";
    import ProgramService from "@/core/services/level/program/ProgramService";
    import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
    import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";


    const levelService = new LevelService()
    const academicYearService = new AcademicYearService();
    const gradeLevelService = new GradeLevelService();

    const programService = new ProgramService();
    const academicClassTimetable = new AcademicCLassTimetableService();
    const academicClassService = new AcademicClassService();
    const userService = new UserService();

    export default {
        name: "TimeTable",
        data() {

            return {
                edit: false,
                create: false,
                academicClassTimetable: {
                    class_id: null,
                    title: null,
                    is_working_sunday: null,
                    is_working_monday: null,
                    is_working_tuesday: null,
                    is_working_wednesday: null,
                    is_working_thursday: null,
                    is_working_friday: null,
                    is_working_saturday: null,
                    is_active: true,
                },
                subjects: [],
                weekdayDialog: false,
                academic_classes: [],
                academic_class: {
                    level_id: null,
                    program_id: null,
                    grade_id: null,
                    shift: null,
                    title: null,
                    is_active: true
                },
                class: null,
                loading: false,
                search: {
                    first_name: '',
                    last_name: '',
                    phone: '',
                    email: '',
                    type: 'teacher',
                    date: null,
                    timeTableId: null,
                    periodId: null,
                    limit: 80,

                },
                users: [],
                time_tables: [],
                programs: [],
                academicYears: [],
                gradesLevels: [],
                levels: [],
                levelId: null,
                page: null,
                total: null,
                perPage: null
            };
        },
        computed: {

            formatDate() {
                return this.class.start_date.split('/').join('-');
            },

            currentUser() {
                return this.$store.getters.currentUser;
            },
        },
        mounted() {
            this.getLevels();
            this.getAcademicYears();
            this.getClass();
            this.getUsers();
            this.getAllTimeTable();

        },
        methods: {

            //fetch Fitlers methods
            changeAcademicYear(){
                this.getLevels();
                this.getClass();
                this.getUsers();
            },

            getAcademicYears() {
                this.loading = true;
                academicYearService.all().then(response => {
                    this.academicYears = response.data;
                }).finally(() => {
                    this.loading = false;
                });
            },
            getLevels() {
                this.loading = true;
                levelService.all().then(response => {
                    this.levels = response.data;
                    if (this.search.id && this.search.level_id) {
                        this.getPrograms();
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            getPrograms() {
                this.loading = true;
                programService.getAllByLevelId(this.search.level_id).then(response => {
                    this.programs = response.data;
                }).finally(() => {
                    this.loading = false;
                });
            },
            getGrades() {
                this.loading = true;
                gradeLevelService.getByProgram(this.search.program_id).then(response => {
                    this.gradesLevels = response.data;

                }).finally(() => {
                    this.loading = false;
                });
            },
            getAcademicClasses() {
                this.loading = true;
                academicClassService.getByProgramLevel(this.search.academic_year_id, this.search.program_id, this.search.grade_id).then(response => {
                    this.academic_classes = response.data;
                }).finally(() => {
                    this.loading = false;
                });
            },

            //end Fetch filters methods
            openWeekdayDialog() {
                this.weekdayDialog = true;
            },

            getClass() {
                this.academicClassTimetable.class_id = this.search.id;

            },

            resetTimeTable() {
                this.academicClassTimetable = {
                    class_id: null,
                    title: null,
                    is_working_sunday: null,
                    is_working_monday: null,
                    is_working_tuesday: null,
                    is_working_wednesday: null,
                    is_working_thursday: null,
                    is_working_friday: null,
                    is_working_saturday: null,
                    is_active: 1,
                }
            },
            getAssignedCourses() {
                this.academicClassTimetable.class_id = this.search.class_id;
                academicClassService.getAssignedCourses(this.search.class_id).then(response => {
                    this.subjects = response.data.courses;
                });
            },
            getUsers() {
                userService.paginate(this.search, this.page).then(response => {
                    this.users = response.data.data;
                });
            },

            getAllTimeTable() {
                this.$bus.emit('toggleLoader');
                academicClassTimetable.paginate().then((response) => {
                    this.time_tables = response.data.data;
                    this.$bus.emit('toggleLoader');
                }).catch((error) => {
                    this.$snotify.error(error)
                    this.$bus.emit('toggleLoader');
                })
            },
            getTimeTable() {
                this.getAssignedCourses();
                this.$bus.emit('toggleLoader');
                academicClassTimetable.getByClass(this.search.class_id).then((response) => {
                    this.academicClassTimetable = response.data.academicClassTimetable;
                    this.time_tables.length = 0;
                    this.time_tables.push(this.academicClassTimetable);
                    this.$bus.emit('toggleLoader');
                }).catch((error) => {
                    this.$snotify.warning('No Time Table Available  Create New')
                    this.time_tables.length = 0;
                    this.resetTimeTable();
                    this.create = true;
                    this.$bus.emit('toggleLoader');
                })
            },
            editTimeTable(timetable) {
                this.edit = true;
                this.academicClassTimetable = timetable;
            },
            createOrUpdae() {
                if (this.edit) {
                    this.updateTimeTable();
                } else {
                    this.createTimeTable();
                }
            },
            createTimeTable() {
                this.academicClassTimetable.class_id = this.search.class_id;
                academicClassTimetable.store(this.academicClassTimetable).then((response) => {
                    this.$router.push({name: 'time-table-period', params: {time_table_id: response.data.time_table.id}})
                    this.weekdayDialog = false;
                    this.$snotify.success("Information updated");
                }).catch((err) => {
                    // console.log(err)
                })
            },
            updateTimeTable() {
                this.academicClassTimetable.class_id = this.search.class_id;
                academicClassTimetable.update(this.academicClassTimetable.id, this.academicClassTimetable).then((response) => {
                    this.$router.push({
                        name: 'time-table-period',
                        params: {time_table_id: this.academicClassTimetable.id}
                    })
                    this.weekdayDialog = false;
                    this.$snotify.success("TimeTable Information updated");
                }).catch((err) => {
                    // console.log(err)
                })
            },
            deleteTimeTable(id) {
                this.$confirm({
                    message: `Are You Suer?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            academicClassTimetable.delete(id).then((response) => {
                                this.$snotify.success("Information updated");
                                this.getAllTimeTable();
                            })
                        }
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>
